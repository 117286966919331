import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class DemoCarousel extends Component {
    render() {
        return (
            <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} >
                <div>
                    <img src="../../festival/offer.jpeg" alt="offer" />
                </div>
                <div>
                    <img src="../../festival/offer1.jpeg" alt="offer1" />
                </div>
            </Carousel>
        );
    }
};


export default DemoCarousel;