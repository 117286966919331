
import React, { useEffect, useState, useRef } from 'react';
import { POST_MATM_REGISTRATION_API, UPLOAD_FILE, GET_ADVERTISEMENT_LIST_API } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
// import AppHeader from './../Header/Header';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { Tooltip } from 'antd';


function Advertisement() {
    const history = useHistory();
    const uploadSlipInput = useRef(null);
    const [getDataField, setDataField] = useState([]);
    const [getDataError, setDataError] = useState({});
    const [getData, setData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);

    const columns = [{
        name: 'Name',
        selector: row => <><Tooltip title={row.name}>{row.name}</Tooltip></>,
        width: "200px"
    }, {
        name: 'Image',
        selector: row => <><Tooltip title={row.image}>{row.image}</Tooltip></>,
    }, {
        name: 'Action',
        cell: row => <></>,
    }]

    const clearFormField = () => {
        // amountInput.current.value = "";
        uploadSlipInput.current.value = "";
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }

        let array = ["AdsName", "mPhoto"];

        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        setDataError(formObject);
        return formValid;
    }

    const handleSubmit = () => {
        if (FormValidate()) {
            const payload = {
                name: getDataField.AdsName,
                image: getDataField.mPhoto,
            }

            postRequest(POST_MATM_REGISTRATION_API, payload, history).then((response) => {
                if (response.success === true) {
                    setDataField([]);
                    setDataError({});
                    clearFormField();
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }).catch(function (error) {
                // console.log(error)
            })
        }
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handleFileChange = (event, name) => {
        // console.log("name ------", name);
        event.preventDefault();
        const data = new FormData();
        data.append('avatar', event.target.files[0], event.target.files[0].name);

        postRequest(UPLOAD_FILE, data, history).then((response) => {
            // console.log("response -----------------", response);
            if (response.success === true) {
                setDataField({ ...getDataField, mPhoto: response.data.fullpath });
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        }).catch(function (error) {
            console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getDataReports = () => {
        getRequest(`${GET_ADVERTISEMENT_LIST_API}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
            } else {
                setData([]);
                setTotalRecord(0)
            }
        }).catch(function (error) {
            console.log(error)
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = page => {
        setPage(page);
    }; // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Advertisement <span className='text-red'></span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='card rounded-0 card-body'>
                                    <div className='row'>
                                        <div className='col-sm-6 col-md-4 col-lg-4'>
                                            <div className="form-group">
                                                <label>Ads Name</label>
                                                <input type="text" className={`form-control normal-light pr-110`} name="AdsName" value={getDataField && getDataField.AdsName} maxLength={15} onChange={(e) => onChangeValue(e)} />
                                                {getDataError && getDataError.AdsName && <span className="error text-danger">The field is required</span>}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputFile">Upload Slip</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" className={`custom-file-input ${getDataError && getDataError.uploadSlip ? "is-invalid" : ""}`} id="exampleInputFile" ref={uploadSlipInput} onChange={(event) => handleFileChange(event)} accept="image/*" />
                                                        <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                                                    </div>
                                                </div>
                                                {getDataError && getDataError.mPhoto && <span className="error text-danger">The field is required</span>}
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-4 col-lg-4'>
                                            <label></label>
                                            <div className='col-md-12 d-flex'>
                                                <div className="form-group  pull-right text-lg-right mr-4">
                                                    <button type="submit" className="btn btn-default rounded-pill pl-4 pr-4" onClick={() => clearFormField()} >Cancel</button>
                                                </div>
                                                <div className="form-group text-lg-right">
                                                    <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSubmit()}>Process</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='history-table'>
                                            <div className="container-fluid pb-5">
                                                <div className="col-md-12">
                                                    <DataTable
                                                        title={""}
                                                        columns={columns}
                                                        data={getData}
                                                        highlightOnHover
                                                        pagination
                                                        paginationServer
                                                        paginationTotalRows={getTotalRecord}
                                                        onChangeRowsPerPage={getDataReports}
                                                        onChangePage={handlePageChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
        </div>
    )
}

export default Advertisement